<template>
  <v-app class="background">
    <app-administrative-Navigation
      :show-menu="showMenu"
      @toggle-menu="toggleMenu"
    ></app-administrative-Navigation>
    <v-main class="mt-5">
      <slot />
      <div
        v-if="showMenu"
        class="overlay"
      >
        <app-administrative-menu-content @toggle-menu="toggleMenu" />
      </div>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      mobileBrowser: true,
      updateAvailable: false,
      showMenu: false,
    }
  },
  mounted() {
    this.mobileBrowser = this.$vuetify.display.mobile && !this.isNativePlatform
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
  },
}
</script>

<style lang="scss">
.background {
  // background: linear-gradient(to bottom right, #5acf62, rgba(255, 153, 150, 0), #296084 97%),
  //    linear-gradient(to top right, white 10%, rgba(255, 153, 150, 0), white) #296084;
  background-image: url('/img/background/dashboard.png');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed !important;
}
.overlay {
  position: fixed;
  top: 0;
  background-color: #296084e0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideUp 0.5s forwards;
  z-index: 1;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
</style>
